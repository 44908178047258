// constants
import { SOCIALS } from "../../constants/social";
import { ROOM_FEATURE_CATEGORIES } from "../../constants/room";
import {
  CONTENT_TYPES,
  CONTENT_SECTION_PAGES,
  CONTENT_SECTION_TYPES,
} from "../../constants/content";
import { CONTENT_ALIASES } from "@cloudspire/shared/constants/content";

type ContentType = {
  id: string;
  type: keyof typeof CONTENT_TYPES;
  alias: string;
  slug: string;
  title: string;
  subtitle: string;
  otherTitle: string;
  otherSubtitle: string;
  about: string;
  coverUrls: object;
};

type ContentSectionType = {
  id: string;
  type: keyof typeof CONTENT_SECTION_TYPES;
  alias: string;
  title: string;
  subtitle: string;
};

type ConfigurationType = {
  channel: {
    id: string;
    title: string;
    about: string;
    children: string[];
    informations: {
      booking: {
        phone: string;
        schedule: string;
        address1: string;
        address2: string;
        zipcode: string;
        city: string;
        country: string;
        email: string;
      };
      contact: {
        phone: string;
        schedule: string;
        address1: string;
        address2: string;
        zipcode: string;
        city: string;
        country: string;
        email: string;
      };
      payment: {
        address1: string;
        address2: string;
        zipcode: string;
        city: string;
        country: string;
      };
    };
    medias: {
      logo: string;
      avatar: string;
    };
    socials: {
      [socialId: string]: {
        url: string;
      } & (typeof SOCIALS)[keyof typeof SOCIALS];
    };
    insurances: Array<{
      id: number;
      name: string;
      company: string;
      company_logo: {
        url: string;
      };
      booklet: {
        url: string;
      };
      marketing_infos: string;
    }>;
    website: {
      domain: string;
      title: string;
      description: string;
      contents: {
        list: ContentType[];
        byAliases: {
          [contentAlias in keyof typeof CONTENT_ALIASES]: ContentType;
        };
        bySection: {
          [contentSectionAlias: string]: ContentType[];
        };
        sections: {
          [sectionPage in keyof typeof CONTENT_SECTION_PAGES]: ContentSectionType[];
        };
      };
      backgroundUrl: string;
      seo: {
        [name: string]: {
          title: string;
          desc: string;
        };
      };
      useSystemRgpd: boolean;
      useBookingDelegation: boolean;
      use_booking_delegation: boolean;
      delegated_channels: object;
      delegatedChannels: {
        [channelId: string]: {
          domain: string;
          name: string;
          informations: {
            booking: {
              address1: string | null;
              address2: string | null;
              zipcode: string | null;
              city: string | null;
              country: string | null;
              email: string | null;
              phone: string | null;
              schedule: string | null;
            };
            bankAccount: {
              iban: string;
              bic: string;
              holder: string;
            };
            contents: {
              list: ContentType[];
              byAliases: {
                [contentAlias in keyof typeof CONTENT_ALIASES]: ContentType;
              };
              bySection: {
                [contentSectionAlias: string]: ContentType[];
              };
              sections: {
                [sectionPage in keyof typeof CONTENT_SECTION_PAGES]: ContentSectionType[];
              };
            };
          };
          website: {
            front: {
              subdomain: string;
            };
            account: {
              subdomain: string;
            };
            booking: {
              subdomain: string;
            };
          };
        };
      };
      front: {
        subdomain: string;
      };
      account: {
        subdomain: string;
      };
      booking: {
        subdomain: string;
      };
    };
    realm: {
      id: string;
      reference: string;
      name: string;
    };
    thirdPartyServices: {
      google: {
        tagManagerKey: string;
        recaptchaSiteKey: string;
        analyticsKey: string;
        apiKey: string;
        siteVerificationKey: string;
      };
    };
    bankAccount: {
      iban: string;
      bic: string;
      holder: string;
    };
    settings: {
      website: {
        contactFormCode: string;
      };
    };
  };
  room: {
    featureList: Array<{
      id: string;
      name: string;
      type: keyof typeof ROOM_FEATURE_CATEGORIES;
      icon: string;
      about: string;
      searchable: boolean;
      useDistance: boolean;
    }>;
    featureIdListByType: {
      [key in keyof typeof ROOM_FEATURE_CATEGORIES]: Array<string>;
    };
    features: {
      [name: string]: {
        id: string;
        name: string;
        type: keyof typeof ROOM_FEATURE_CATEGORIES;
        icon: string;
        about: string;
        searchable: boolean;
        useDistance: boolean;
      };
    };
    categoryNameList: Array<string>;
  };
};

/**
 * Retourne les contenus et les sections de contenus formattés pour la configuration
 * @param {object} param0
 * @param {object} param0.data
 * @return {object}
 */
function getContents({ contents }) {
  const normalizedContents = {};
  const normalizedContentByAlias = {};
  const normalizedContentBySection = {};
  const normalizedSections = {};
  const rawContents = contents.all ?? {};
  for (const [rawContentId, rawContent] of Object.entries<any>(rawContents)) {
    normalizedContents[rawContentId] = {
      id: rawContent?.id,
      type: rawContent?.type,
      alias: rawContent?.alias,
      slug: rawContent?.slug,
      title: rawContent?.title,
      subtitle: rawContent?.subtitle,
      otherTitle: rawContent?.otherTitle,
      otherSubtitle: rawContent?.otherSubtitle,
      about: rawContent?.about,
      coverUrls: rawContent?.miniCoverUrls,
    };

    if (rawContent?.alias in CONTENT_ALIASES) {
      normalizedContentByAlias[rawContent.alias] = rawContent;
    }
  }
  const rawContentBySections = contents?.by_section ?? {};
  for (const [rawSectionAlias, contentList] of Object.entries<Array<any>>(
    rawContentBySections
  )) {
    normalizedContentBySection[rawSectionAlias] = contentList.map(function (
      contentId
    ) {
      return normalizedContents[contentId];
    });
  }
  const rawContentSections = contents?.sections ?? {};
  for (const [contentSectionAlias, contentSection] of Object.entries(
    rawContentSections
  )) {
    normalizedSections[contentSectionAlias] = contentSection;
  }

  return {
    contentList: Object.values(normalizedContents),
    contentByAlias: normalizedContentByAlias,
    contentsBySection: normalizedContentBySection,
    contentSections: normalizedSections,
  };
}

/**
 * Retourne la liste des réseaux sociaux supportés.
 * @param param0
 * @param param0.data
 * @return {object}
 */
function getSocials({ data }) {
  const normalizedSocials = {};
  const rawSocials = data?.channel?.socials ?? {};
  for (const [socialId, socialUrl] of Object.entries(rawSocials)) {
    if (socialId in SOCIALS) {
      normalizedSocials[socialId] = {
        ...SOCIALS[socialId],
        url: socialUrl,
      };
    }
  }
  return { socials: normalizedSocials };
}

/**
 * Retourne le channel délégué.
 * @param param0
 * @return {object}
 */
function getDelegatedChannels({ data }) {
  const delegatedChannels = data?.channel?.website?.delegated_channels ?? {};

  const output = {};

  for (const [delegatedChannelId, delegatedChannel] of Object.entries<any>(
    delegatedChannels
  )) {
    const { contentList, contentByAlias, contentSections, contentsBySection } =
      getContents({ contents: delegatedChannel?.informations?.contents ?? {} });

    output[delegatedChannelId] = {
      domain: delegatedChannel.domain,
      name: delegatedChannel.name,
      informations: {
        booking: delegatedChannel?.informations?.booking,
        payment: delegatedChannel?.informations?.payment,
        bankAccount: delegatedChannel?.informations?.bank_account,
        contents: {
          list: contentList,
          byAliases: contentByAlias,
          bySection: contentsBySection,
          sections: contentSections,
        },
      },
      website: {
        front: {
          subdomain: delegatedChannel?.website?.front?.subdomain,
        },
        account: {
          subdomain: delegatedChannel?.website?.account?.subdomain,
        },
        booking: {
          subdomain: delegatedChannel?.website?.booking?.subdomain,
        },
      },
    };
  }

  return output;
}

export default function configurationParseData({ data }): ConfigurationType {
  const { contentList, contentByAlias, contentSections, contentsBySection } =
    getContents({ contents: data?.channel?.website?.contents ?? {} });
  const { socials } = getSocials({ data });

  const delegatedChannels = getDelegatedChannels({ data });

  return {
    channel: {
      id: data?.channel?.id,
      title: data?.channel?.title,
      about: data?.channel?.about,
      children: data?.channel?.children ?? [],
      informations: {
        booking: {
          phone: data?.channel?.informations?.booking?.phone,
          schedule: data?.channel?.informations?.booking?.schedule,
          address1: data?.channel?.informations?.booking?.address1,
          address2: data?.channel?.informations?.booking?.address2,
          zipcode: data?.channel?.informations?.booking?.zipcode,
          city: data?.channel?.informations?.booking?.city,
          country: data?.channel?.informations?.booking?.country,
          email: data?.channel?.informations?.booking?.email,
        },
        contact: {
          phone: data?.channel?.informations?.contact?.phone,
          schedule: data?.channel?.informations?.contact?.schedule,
          address1: data?.channel?.informations?.contact?.address1,
          address2: data?.channel?.informations?.contact?.address2,
          zipcode: data?.channel?.informations?.contact?.zipcode,
          city: data?.channel?.informations?.contact?.city,
          country: data?.channel?.informations?.contact?.country,
          email: data?.channel?.informations?.contact?.email,
        },
        payment: {
          address1: data?.channel?.informations?.payment?.address1,
          address2: data?.channel?.informations?.payment?.address2,
          zipcode: data?.channel?.informations?.payment?.zipcode,
          city: data?.channel?.informations?.payment?.city,
          country: data?.channel?.informations?.payment?.country,
        },
      },
      medias: {
        logo: data?.channel?.medias?.logo,
        avatar: data?.channel?.medias?.avatar,
      },
      socials,
      insurances: data?.channel?.insurances ?? [],
      website: {
        domain: data?.channel?.website?.domain,
        title: data?.channel?.website?.title,
        description: data?.channel?.website?.description,
        backgroundUrl: data?.channel?.website?.background_url,
        contents: {
          list: contentList,
          byAliases: contentByAlias,
          bySection: contentsBySection,
          sections: contentSections,
        },
        seo: data?.channel?.website.seo ?? {},
        useSystemRgpd: data?.channel?.website?.use_system_rgpd ?? true,
        useBookingDelegation:
          data?.channel?.website?.use_booking_delegation ?? false,
        use_booking_delegation:
          data?.channel?.website?.use_booking_delegation ?? false,
        delegatedChannels,
        delegated_channels: delegatedChannels,
        front: {
          subdomain: data?.channel?.website?.front?.subdomain,
        },
        account: {
          subdomain: data?.channel?.website?.account?.subdomain,
        },
        booking: {
          subdomain: data?.channel?.website?.booking?.subdomain,
        },
      },
      realm: {
        id: data?.channel?.realm?.id,
        reference: data?.channel?.realm?.reference,
        name: data?.channel?.realm?.name,
      },
      thirdPartyServices: {
        google: {
          recaptchaSiteKey:
            data?.channel?.third_party_services?.google?.recaptcha_site_key,
          tagManagerKey:
            data?.channel?.third_party_services?.google?.tag_manager_key,
          analyticsKey:
            data?.channel?.third_party_services?.google?.analytics_key,
          apiKey: data?.channel?.third_party_services?.google?.api_key,
          siteVerificationKey:
            data?.channel?.third_party_services?.google?.site_verification_key,
        },
      },
      bankAccount: {
        iban: data?.channel?.bank_account?.iban,
        bic: data?.channel?.bank_account?.bic,
        holder: data?.channel?.bank_account?.holder,
      },
      settings: {
        website: {
          contactFormCode: data?.channel?.settings?.website?.contact_form_code,
        },
      },
    },
    room: {
      categoryNameList: data?.channel?.room_categories ?? [],
      ...(data?.room?.features ?? []).reduce(
        (accumulator, feature) => {
          const featureType = feature?.type;
          const featureId = feature?.id;

          const tempFeature = {
            id: featureId,
            name: feature?.name,
            type: featureType,
            icon: feature?.icon,
            about: feature?.about,
            searchable: feature?.is_searchable,
            useDistance: feature?.use_distance,
          };

          if (!Array.isArray(accumulator.featureIdListByType[featureType])) {
            accumulator.featureIdListByType[featureType] = [];
          }

          accumulator.featureList.push(tempFeature);
          accumulator.features[featureId] = tempFeature;
          accumulator.featureIdListByType[featureType].push(featureId);

          return accumulator;
        },
        {
          featureList: [],
          features: {},
          featureIdListByType: {},
        }
      ),
    },
  };
}
